import { biddingOptions } from '../../static';
import useFilterCom from "@/composition/utils/useFilter.js"
import { useFilter } from '../../stores';
import configuration from "../../config.json";
import { computed } from 'vue';

export default {
  setup() {
  
    const { sortLotProduct,
      sortByOptions, 
      filterState,
      handleFiletrClear,
      handleFilterApply,
      category,
      subCategories,
      condition,
      biddingMode,
      categoryElements,
      subCategoryElements,
      conditionElements,
      locationElements,
      handleFilterClear,
      handleClose
    } =  useFilterCom()

    const showBidModes = computed(() => {
      return configuration.Lots.Open.enabled
    });

    const filterStore = useFilter();

    return {
      sortByOptions,
      showBidModes,
      filterState,
      category,
      subCategories,
      condition,
      filterStore,
      location,
      biddingMode,
      categoryElements,
      subCategoryElements,
      configuration,
      conditionElements,
      locationElements,
      sortLotProduct,
      handleFilterApply,
      handleFilterClear,
      handleClose
    };
  },

  data() {
    const { Open} =  configuration.Lots;
    if(!Open.enabled) {
      biddingOptions.shift()
    }
  
    return {
      activeItem:  (Open.enabled) ? 0 : 1,
      biddings: biddingOptions,
    }
  },
  methods: {
    toggleItem(index) {
      if (this.activeItem === index) {
        this.activeItem = -1;
      } else {
        this.activeItem = index;
      }
    },
  },
};
