import { computed, onMounted, reactive, ref, watch } from 'vue'
import { uselotProducts, useFilter, usePaginationStore } from '@/stores';
import { useRoute, useRouter } from 'vue-router';
import { sortOptions, scbLotSortOptions, moqSortOptions } from "@/static"
import { loaderStore } from '../../stores';
import { isMobile } from "@/misc/index"
import configuration from "../../config.json";

export default function useFilterCom() {
    const filterStore = useFilter()
    const paginationstore = usePaginationStore()
    const loader = loaderStore();

    const categoryElements = computed(() => filterStore.categories_options)
    const subCategoryElements = computed(() => filterStore.sub_categories_options)
    const conditionElements = computed(() => filterStore.conditions_options)
    const locationElements = computed(() => filterStore.location_options)

    const router = useRouter()
    const route = useRoute()

    const initialStateValues = {
        isOpen: false,
        debounce: null,
        dialog: false,
        priceRange: [0, 12000000],
        sortBy: "+end_date",
        search: "",
        BiddingMode: 'Hybrid',
        lotType: 'Hybrid',
        category_id: [],
        sub_categories: [],
        location: [],
        condition: [],
        selectedSortBy : "end_date_l_h",
        filterBy : ""
    }

    const filterState = reactive({ ...initialStateValues })
    const lotStore = uselotProducts();
     const updatePath = () => {
        const pathToRedirect = lotStore.organization_id ? `/${lotStore.organization_id}/products` : '/products';
        let query = {
            search: (route.query.BiddingMode && (filterState.BiddingMode !== route.query.BiddingMode)) ? "" : filterState.search,
            condition: [...filterState.condition],
            priceRange: [...filterState.priceRange],
            category_id: [...filterState.category_id],
            sub_categories: [...filterState.sub_categories],
            location: [...filterState.location],
            sortBy: filterState.sortBy,
            BiddingMode: filterState.BiddingMode,
            page: paginationstore.page,
            perPage: paginationstore.perPage,
            selectedSortBy: filterState.selectedSortBy
        }

        let objQuery = { ...route.query, ...query };
        router.push({ path: pathToRedirect, params: { ...route.params }, query: objQuery });
    };


    const filterLotProducts = () => {
        updatePath()
    };


    const setFilterState = (stateName, value) => {
        const pathToRedirect = lotStore.organization_id ? `/${lotStore.organization_id}/products` : '/products';

        const isBiddingMode = stateName === "BiddingMode"
        let objQuery = {};
        if (isBiddingMode) {
            const lotModeVal = isBiddingMode ? value : filterState.BiddingMode;
            paginationstore.$reset()
            objQuery =  {BiddingMode: lotModeVal, search: ''}
            router.push({ path: pathToRedirect, params: { ...route.params }, query: {...objQuery, page: 1 } });
        } else {
            objQuery = {...route.query, [stateName]: value}
            router.push({ path: pathToRedirect, params: { ...route.params }, query: {...objQuery} });
        }

    }

    const sortLotProduct = (sortBy) => {
        setFilterState('sortBy', sortBy)
        setFilterState('selectedSortBy', sortBy)
    };

    const sortByOptions = computed(() => filterState.BiddingMode === "Hybrid" ? sortOptions : (filterState.BiddingMode === 'moq' ? moqSortOptions : scbLotSortOptions))

    const handleSearch = () => {
        filterLotProducts()
    }

    const setFilterStateFromUrl = () => {
        let filterKeys = Object.keys(route.query)
        let clonedState = {...initialStateValues, lotType: filterState.lotType }
        if (filterKeys.length > 0) {
            let ArrayKeys = ["condition", "category_id", "location", "sub_categories"]
            loader.setLotLoading(true);
            for (let stateName of filterKeys) {
                if (route.query[stateName]) {
                    if (ArrayKeys.includes(stateName) && !Array.isArray(route.query[stateName])) {
                        clonedState[stateName] = [route.query[stateName]]
                    } else if (stateName === 'page') {
                        paginationstore.setPage(route.query[stateName])
                    }
                    else {
                        clonedState[stateName] = route.query[stateName]
                    }
                }
            }
        }

        Object.assign(filterState, initialStateValues, clonedState)
    }

    onMounted(() => {
        setFilterStateFromUrl()
    })



    const handleFilterClear = () => {
        Object.assign(filterState, {...initialStateValues, dialog: false, search: '', BiddingMode: filterState.BiddingMode, lotType: filterState.lotType})
        filterStore.lotFilterOptions(filterState.BiddingMode)
        filterLotProducts();
    }

    const handleClose = () => {
        filterState.dialog = false
    }

    const handleFilterApply = () => {
        filterState.dialog = false
        updatePath()
        loader.setLotLoading(true)
    }

    let searchTimerId;

    watch(() => ({...filterState}), (newValue, oldValue) => {
        if(!isMobile() && route.path.match('/products')) {
            if(oldValue.BiddingMode !== newValue.BiddingMode) {
                lotStore.setSuccess();
                if(newValue.BiddingMode === 'Open') {
                    filterStore.setPriceRange([0,12000000])
                }
                setFilterState('BiddingMode', newValue.BiddingMode)
            } else if(oldValue.search !== newValue.search) {
                if((newValue.search.length >= 3) || !newValue.search) {
                    clearTimeout(searchTimerId)
                    searchTimerId = setTimeout(() => {
                        updatePath()
                    }, 500)
                }
            }  else {
                if(route.path.match('/products') && JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                    updatePath()
                } 
            }
        } else {
            if((route.path.match('/products') && ([oldValue.BiddingMode, newValue.BiddingMode].indexOf('moq') > -1) && oldValue.BiddingMode !== newValue.BiddingMode)) {
                Object.assign(filterState, {...filterState, search: ''});
                setFilterState('BiddingMode', newValue.BiddingMode)
            } else {
                const baseRange = [0,12000000];
                if(newValue.BiddingMode !== oldValue.BiddingMode) {
                    Object.assign(filterState, {...filterState, search: ''});
                    if(newValue.BiddingMode === 'Open') {
                        filterStore.setPriceRange(baseRange)
                    }
                }
            }
        }
    })

    watch(() => paginationstore.page, () => {
        window.scrollTo(0,0)
    })

    watch(() => route.query, async (newVal, oldVal) => {
        if(newVal !== oldVal) {
            setFilterStateFromUrl();
        }
    })

    const sortValue = filterState.BiddingMode === 'Open' ? filterState.selectedSortBy : filterState.sortBy;
    
    return {
        sortByOptions,
        categoryElements,
        sortValue,
        subCategoryElements,
        conditionElements,
        locationElements,
        filterState,
        filterLotProducts,
        sortLotProduct,
        handleFilterClear,
        handleSearch,
        updatePath,
        handleFilterApply,
        configuration,
        handleClose
    };
}
