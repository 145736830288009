import { biddingOptions } from '../../static';
import useFilterCom from "@/composition/utils/useFilter.js"
import { loaderStore, useFilter } from "../../stores";
import { ref, watch, reactive } from 'vue';
import configuration from "../../config.json";

export default {
  setup() {
    const filterStore = useFilter()
    const loader = loaderStore();
    const pageLoading = reactive({
      isLoading: false
    });

    const {
      categoryElements,
      subCategoryElements,
      conditionElements,
      locationElements,
      filterLotProducts,
      filterState,
      handleFilterClear
    }  = useFilterCom()

    const filterData = ref(filterState);

    watch(loader, () => {
      pageLoading.isLoading = !!loader.lotLoader
    })

    const handleSelect = (a,b) => {
      return b === 'Hybrid' ? (['Hybrid', 'moq'].indexOf(a) > -1) : b === a; 
    }
    console.log(filterData)
    return {
      filterState: filterData,
      handleSelect,
      categoryElements,
      filterStore,
      subCategoryElements,
      pageLoading,
      configuration,
      conditionElements,
      locationElements,
      filterLotProducts,
      handleFilterClear
    };
  },

  props: {
    value: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      panel: [0, 1, 2, 3, 4, 5, 6, 7],
      biddings: biddingOptions,
    };
  },
};